<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm :title="formTitle"
                       in-progress-message="Updating Student Group, Please wait..."
                       success-message="Student Group Added Successfully"
                       :action-text="formTitle"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="addEditStudentGroup"
                       @close="$refs.sideBar.emitToggle()">
      <div>
        <DoodleInput field-name="name" label-text="Student Group Name" type="text"
                     leftIcon="users"
                     v-model="doodleForm.name" :model="doodleForm.name"
                     rule="required|min:2|max:255" place-holder="student@gmail.com"/>
        <DoodleTextArea field-name="description" label-text="Description"
                     :model="doodleForm.description"
                     v-model="doodleForm.description" rule="required|min:2|max:10000" rows="5"/>
      </div>
      <template v-slot:successMessage>
        <div class="has-text-centered">
          <div class="register-thanks" v-if="isAdd">
            Student Group added!
          </div>
          <div class="register-thanks" v-if="isEdit">
            Student Group Updated!
          </div>
        </div>
      </template>
    </DoodleGeneralForm>
    <div class="guru-hints">
      <span class="has-text-weight-bold is-underlined">Hints:</span> Use Student Groups to bulk import students into assignments.
      Every student group can be managed/used only by the account owner, the user created the student group and the admins/teachers included
      as administrator in the particular student group.
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import DoodleInput from '../Utils/DoodleInput'
import DoodleGeneralForm from '../Utils/DoodleGeneralForm'
import SideBar from '../Utils/SideBar'
import { eventBus } from './../../assets/javascript/event-bus'
import { GURU_EVENTS, GURU_FORM_STATUS } from '../../assets/javascript/constants'
import DoodleTextArea from '../Utils/DoodleTextArea'

export default {
  name: 'guruAddEditStudentGroup',
  mixins: [formMixin],
  inject: ['$validator'],
  components: { DoodleTextArea, SideBar, DoodleGeneralForm, DoodleInput },
  props: ['mode', 'instituteCode', 'email', 'initData'],
  data: function () {
    return {
      doodleForm: {
        insStudentGroupId: null,
        name: null,
        description: null
      },
      editMode: false
    }
  },
  mounted () {
    Object.assign(this.doodleForm, this.initData)
  },
  methods: {
    addEditStudentGroup () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/addStudentGroup',
        data: {
          instituteCode: this.instituteCode,
          name: this.doodleForm.name,
          description: this.doodleForm.description,
          insStudentGroupId: this.doodleForm.insStudentGroupId,
          mode: this.localMode
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (this.isAdd) {
            eventBus.$emit(GURU_EVENTS.ADD_TO_STUDENT_GROUP, data.sg)
          } else {
            eventBus.$emit(GURU_EVENTS.REPLACE_IN_STUDENT_GROUP, this.doodleForm)
          }
        },
        jdaEvent: (this.isAdd) ? 'guru-add-student-group' : 'guru-update-student-group',
        jdaLabel: ''
      })
    }
  },
  computed: {
    formTitle () {
      let titlePrefix = ''

      if (this.localMode === GURU_FORM_STATUS.ADD) {
        titlePrefix = 'Add'
      } else if (this.localMode === GURU_FORM_STATUS.EDIT) {
        titlePrefix = 'Update'
      }

      return titlePrefix + ' Student Group'
    },
    localMode () {
      if (this.editMode) {
        return GURU_FORM_STATUS.EDIT
      }

      return this.mode
    },

    isAdd () {
      return this.localMode === GURU_FORM_STATUS.ADD
    },
    isEdit () {
      return this.localMode === GURU_FORM_STATUS.EDIT
    }
  }
}
</script>

<style scoped lang="scss">

</style>
